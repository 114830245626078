<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-card class="mt-2 pb-8 mb-5" flat>
					<v-card-title>
						<v-subheader>
							<span class="title font-weight-normal">Filtrar tipos</span>
						</v-subheader>
						<v-spacer></v-spacer>
						<v-btn
							v-show="!view"
							class="white--text mr-2"
							depressed
							color="#34bfa3"
							@click="$router.push('/type/new')"
							>+ Novo</v-btn
						>
						<v-btn class="white--text" depressed color="info" @click="filter()"
							>Buscar</v-btn
						>
					</v-card-title>
					<v-spacer></v-spacer>
					<v-divider></v-divider>
					<v-card-text>
						<v-row class="pl-6">
							<v-col cols="12" md="4" sm="12" xs="12" lg="3">
								<v-text-field
									v-model="filters.description"
									label="Descrição"
								></v-text-field>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
				<v-card flat>
					<v-data-table
						:headers="headers"
						:items="items"
						:items-per-page="pagination.itemsPerPage"
						v-bind:loading="loading"
						loading-text="Aguarde...carregando"
						:hide-default-footer="true"
					>
						<template v-slot:body="{ items }">
							<tr v-for="item in items" :key="item.CODTIP">
								<td v-if="!view">
									<v-btn @click="edit(item.CODTIP)" icon>
										<v-icon style="color: orange">mdi-pencil</v-icon>
									</v-btn>
								</td>
								<td v-else>&nbsp;</td>
								<td>{{ item.CODTIP }}</td>
								<!-- <td>{{ item.company.DESABR }}</td>
								<td>{{ item.unit.DESABR }}</td> -->
								<td>{{ item.DESTIP }}</td>
								<td v-if="!view">
									<v-btn @click="remove(item.CODTIP)" icon>
										<v-icon style="color: red">mdi-delete</v-icon>
									</v-btn>
								</td>
								<td v-else>
									<v-btn @click="edit(item.CODTIP)" icon>
										<v-icon>mdi-eye</v-icon>
									</v-btn>
								</td>
							</tr>
						</template>
					</v-data-table>

					<pagination
						v-model="pagination"
						@change="loadData"
						:loading="loading"
					/>
				</v-card>
			</v-col>
		</v-row>
		<template>
			<Dialog ref="confirm" />
		</template>
	</v-container>
</template>

<script>
import Dialog from "@/components/misc/Dialog";
import TypeService from "../../app/Services/TypeService";
import pagination from "@/components/pagination/Pagination.vue";
import PaginationMixin from "@/components/pagination/PaginationMixin";
import SortableMixin from "@/components/pagination/sort/SortableMixin";

const typeService = TypeService.build();

export default {
	name: "TypeList",
	components: { pagination, Dialog },
	mixins: [PaginationMixin, SortableMixin],
	computed: {},
	mounted() {
		const userData = JSON.parse(localStorage.getItem("user"));

		if (userData) {
			this.view = userData.user.TIPUSU === "P";
		}

		this.loadData();
	},
	data: () => ({
		loading: false,
		view: false,
		filters: {},
		headers: [
			{
				text: "",
				align: "left",
				sortable: false,
				value: "status",
				width: "3%",
			},
			{
				text: "Código",
				align: "left",
				sortable: true,
				value: "CODTIP",
				width: "10%",
			},
			// {
			// 	text: "Empresa",
			// 	align: "left",
			// 	sortable: true,
			// 	value: "company.DESABR",
			// 	width: "10%",
			// },
			// {
			// 	text: "Unidade",
			// 	align: "left",
			// 	sortable: true,
			// 	value: "unit.DESABR",
			// 	width: "10%",
			// },
			{
				text: "Nome",
				align: "left",
				sortable: true,
				value: "DESTIP",
			},
			{
				text: "",
				align: "left",
				sortable: false,
				value: "status",
				width: "3%",
			},
		],
		items: [],
	}),
	methods: {
		toast(type, message, icon = "mdi-check") {
			this.$toasted.show(message, {
				type: type,
				icon: icon,
			});
		},
		async loadData() {
			try {
				this.loading = true;

				const filter = this.filters;
				const query = {
					params: {
						filter,
						...this.pagination,
					},
				};

				const response = await typeService.index({ query });

				this.items = response.data;

				this.paginate(response);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log(e);
				this.defaultCatchError(e);
			} finally {
				this.loading = false;
			}
		},
		filter() {
			this.searching = true;
			this.items = [];
			this.pagination.page = 1;

			this.loadData();
		},
		edit(id) {
			this.$router.push({
				name: "TypeEdit",
				params: {
					id: id,
					view: this.view,
				},
			});
		},
		async remove(id) {
			await this.$refs.confirm
				.open("Atenção", "Deseja mesmo excluir o tipo?")
				.then(() => {
					this.$root.$Loading.show("Removendo tipo...");

					typeService
						.destroy({
							id: id,
						})
						.then(() => {
							this.toast("success", "Tipo removido com sucesso!");

							this.loadData();
						})
						.finally(() => {
							this.$root.$Loading.hide();
						});
				});
		},
	},
};
</script>
